<template>
  <div class="test-heading">
    <div class="case-creation__card">
      <div class="text-information-card">
        <div class="case-creation__heading border-bottom-none">
          <div class="title-with-icon">
            <span class="icon">
              <img
                src="@/assets/images/icons/icon-test-info.svg"
                alt="icon"
                class="img-fluid"
              />
            </span>
            <h3 class="title">Test Information</h3>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section
    v-loading="loading"
    class="selected-tests-information"
    v-if="
      panelsTestsData.selectedTestIds &&
      panelsTestsData.selectedTestIds.length > 0
    "
  >
    <el-checkbox-group
      v-model="panelsTestsData.selectedTestIds"
      class="case-vue-tabs"
    >
      <el-tabs>
        <el-tab-pane
          v-for="(test, index) of getSelectedCaseTypes()"
          :key="index"
        >
          <template #label>
            {{ caseTypeMap[test.case_type_code] }}
          </template>
          <div v-if="test.case_type_code == 'TOXICOLOGY'">
            <ToxicologyTestsPreview
              :panelTestsData="panelsTestsData"
              :test="test"
              :allSelectedTests="allTests"
            ></ToxicologyTestsPreview>
          </div>
          <div v-else-if="test.case_type_code == 'WOUND'">
            <WoundPanelSelectedTests
              :panelTestsData="panelsTestsData"
              :test="test"
              :allSelectedTests="allTests"
            ></WoundPanelSelectedTests>
          </div>
          <div v-else-if="test.case_type_code == 'NAIL'">
            <NailPanelSelectedTests
              :panelTestsData="panelsTestsData"
              :test="test"
              :allSelectedTests="allTests"
            ></NailPanelSelectedTests>
          </div>
          <div v-else-if="test.case_type_code == 'GASTRO'">
            <GastroPanelSelectedTests
              :panelTestsData="panelsTestsData"
              :test="test"
              :allSelectedTests="allTests"
            ></GastroPanelSelectedTests>
          </div>
          <div v-else-if="test.case_type_code == 'UTI'">
            <UTIPanelSelectedTests
              :panelTestsData="panelsTestsData"
              :test="test"
              :allSelectedTests="allTests"
            ></UTIPanelSelectedTests>
          </div>
          <div v-else-if="test.case_type_code == 'CLINICAL_CHEMISTRY'">
            <BloodPanelSelectedTests
              :panelTestsData="panelsTestsData"
              :test="test"
              :allSelectedTests="allTests"
            ></BloodPanelSelectedTests>
          </div>
          <div v-else-if="test.case_type_code == 'GTI_WOMENS_HEALTH'">
            <GTIWomensHealthTests
              :panelTestsData="panelsTestsData"
              :newCase="checkCaseValidation"
              :test="test"
              :allSelectedTests="allTests"
            ></GTIWomensHealthTests>
          </div>
          <div v-else>
            <DefaultPanelSelectedTests
              :panelTestsData="panelsTestsData"
              :testType="test"
              :allSelectedTests="allTests"
              :caseType="test.case_type_code"
            ></DefaultPanelSelectedTests>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-checkbox-group>
  </section>
</template>
  
  <script>
import { mapGetters } from "vuex";
import caseConstants from "@/config/constants/caseConstants";
import { defineAsyncComponent } from "vue";
const WoundPanelSelectedTests = defineAsyncComponent(() =>
  import(
    "@/components/cases/testInfo/selectedTestsPreview/WoundPanelSelectedTests"
  )
);
const UTIPanelSelectedTests = defineAsyncComponent(() =>
  import(
    "@/components/cases/testInfo/selectedTestsPreview/UTIPanelSelectedTests"
  )
);
const BloodPanelSelectedTests = defineAsyncComponent(() =>
  import(
    "@/components/cases/testInfo/selectedTestsPreview/BloodPanelSelectedTests"
  )
);
const ToxicologyTestsPreview = defineAsyncComponent(() =>
  import("@/components/panels/testsPreview/ToxicologyTestsPreview")
);
const DefaultPanelSelectedTests = defineAsyncComponent(() =>
  import(
    "@/components/cases/testInfo/selectedTestsPreview/DefaultPanelSelectedTests"
  )
);
const NailPanelSelectedTests = defineAsyncComponent(() =>
  import(
    "@/components/cases/testInfo/selectedTestsPreview/NailPanelSelectedTests"
  )
);
const GastroPanelSelectedTests = defineAsyncComponent(() =>
  import(
    "@/components/cases/testInfo/selectedTestsPreview/GastroPanelSelectedTests"
  )
);
const GTIWomensHealthTests = defineAsyncComponent(() =>
  import(
    "@/components/cases/testInfo/selectedTestsPreview/GTIWomensHealthTests"
  )
);
export default {
  components: {
    WoundPanelSelectedTests,
    UTIPanelSelectedTests,
    ToxicologyTestsPreview,
    BloodPanelSelectedTests,
    DefaultPanelSelectedTests,
    NailPanelSelectedTests,
    GastroPanelSelectedTests,
    GTIWomensHealthTests,
  },
  data() {
    return {
      loading: false,
      selectedTestIds: [],
      allTests: [],
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
      caseId: "",
      UTIPanels: [],
      selectedCoriellPanels: [],
      selectedTab: null,
      panelsTestsData: {
        selectedTestIds: [],
        selectedCoriellPanels: [],
      },
    };
  },
  mounted() {
    this.checkTestInformation();
  },
  computed: {
    ...mapGetters("clientPendingOrders", ["getPendingOrderDetails"]),
    getTestsInformation() {
      if (
        this.getPendingOrderDetails &&
        Object.keys(this.getPendingOrderDetails).length > 0 &&
        this.getPendingOrderDetails.tests_info
      ) {
        return this.getPendingOrderDetails.tests_info &&
          this.getPendingOrderDetails.tests_info.length
          ? this.getPendingOrderDetails.tests_info
          : null;
      }
      return null;
    },
    getTestsInformationObject() {
      const obj = {};
      if (this.allTests) {
        this.allTests.forEach((el) => {
          obj[el._id] = el;
        });
      }
      return obj;
    },
    selectedTestsDetails() {
      const data = [];
      if (this.panelsTestsData.selectedTestIds) {
        this.panelsTestsData.selectedTestIds.forEach((el) => {
          if (el && this.getTestsInformationObject[el]) {
            data.push(this.getTestsInformationObject[el]);
          }
        });
      }

      return data;
    },
  },
  methods: {
    async checkTestInformation() {
      this.loading = true;
      let params = {
        order_id: this.$route.params.order_id,
      };
      if (this.$route.params.order_id) {
        await this.$store.dispatch(
          "clientPendingOrders/fetchPendingOrderDetails",
          params
        );
        if (
          this.getPendingOrderDetails &&
          this.getPendingOrderDetails.tests_info
        ) {
          this.panelsTestsData.selectedTestIds =
            this.getPendingOrderDetails.tests_info.map((el) => el._id);
        }

        if (
          this.getPendingOrderDetails.coriell_test_panels &&
          this.getPendingOrderDetails.coriell_test_panels.length
        ) {
          this.panelsTestsData.selectedCoriellPanels =
            this.getPendingOrderDetails.coriell_test_panels;
        }

        this.allTests = this.getPendingOrderDetails.tests_info;

        this.loading = false;
      }
    },

    getSelectedCaseTypes() {
      if (
        this.getPendingOrderDetails.case_types &&
        this.getPendingOrderDetails.case_types.length
      ) {
        if (this.selectedTestsDetails) {
          let flags = [],
            output = [],
            outputWithFullDetails = [],
            l = this.selectedTestsDetails.length,
            i;
          for (i = 0; i < l; i++) {
            if (flags[this.selectedTestsDetails[i].case_type_code]) continue;
            flags[this.selectedTestsDetails[i].case_type_code] = true;
            output.push(this.selectedTestsDetails[i].case_type_code);
            outputWithFullDetails.push(this.selectedTestsDetails[i]);
          }
          if (outputWithFullDetails.length) {
            this.selectedTab = outputWithFullDetails.length - 1 + "";
          }

          return outputWithFullDetails;
        }
      } else {
        this.allTests = [];
        this.panelsTestsData.selectedTestIds = [];
        this.panelsTestsData.selectedCoriellPanels = [];
      }
      return [];
    },
  },
};
</script>
  
  <style lang="scss">
.test-heading {
  .case-creation__card {
    .text-information-card {
      display: flex;
      justify-content: space-between;
    }
    .test-information-header {
      display: flex;
      align-content: center;
      p {
        font-size: 13px;
        font-weight: 500;
        margin: 0 5px;
        color: #f24a55;
        margin-top: 1px;
      }
      .el-button {
        border: 0;
        padding: 0 4px 0 12px;
        min-height: 30px;
        &:hover {
          background-color: #fff;
        }
        &:active {
          background-color: #fff;
        }
        &:focus {
          background-color: #fff;
        }
        .edit-icon {
          display: flex;
          align-items: center;

          img {
            width: 15px;
            height: 15px;
          }
          p {
            color: #828282;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
  